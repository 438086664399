import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useState, useRef, useMemo } from 'react'

import Column from '@thebeansgroup/ui_grids/column'
import Row from '@thebeansgroup/ui_grids/row'
import FilterIcon from '@thebeansgroup/ui_icons/filter_icon'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

import BeansIdButton from '@components/beans_id_button'
import CategoryPills from '@components/category_pills'
import Container from '@components/container'
import HomePageOfferListCCGFilters from '@components/home_page_offer_list_ccg_filters'
import OfferListAlgolia from '@components/offer_list_algolia'
import OfferListAlgoliaFilters from '@components/offer_list_algolia_filters'
import OfferListAlgoliaFiltersModal from '@components/offer_list_algolia_filters_modal'
import { DEFAULT_SORT } from '@constants/home_page'
import { getAlgoliaResults } from '@helpers/algolia'
import { getCategoryFromParams } from '@helpers/categories'
import { updateData } from '@helpers/offer_list_algolia'
import { useCCG } from '@hooks/use_ccg'
import { useCountryCode } from '@hooks/use_country_code'

import { IMPRESSION_CONTENT } from './constants'
import {
  getFiltersArray,
  getInitialFilters,
  shouldRenderCategoryPills
} from './helpers'
import * as styles from './styles'

const AlgoliaPageOfferList = ({ offers, isHomePage }) => {
  const ccg = useCCG()
  const router = useRouter()
  const category = getCategoryFromParams(router?.query)
  const [filters, setFilters] = useState(getInitialFilters(ccg, isHomePage))
  const initialRender = useRef(true)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)

  const [data, setData] = useState(offers)
  const [availableBrandFilters, setAvailableBrandFilters] = useState(
    offers?.facets?.brandName
  )
  const [availableCategoryFilters, setAvailableCategoryFilters] = useState(
    offers?.facets?.categories
  )
  const [hasCCGChanged, setHasCCGChanged] = useState(false)

  const [sort, setSort] = useState(DEFAULT_SORT)
  const country = useCountryCode()

  const handleCCGClick = (ccgFilter) => {
    setFilters([ccgFilter])
    setHasCCGChanged(true)
  }

  const handleFilterClick = (value, filter) => {
    setFilters(getFiltersArray(filter, filters))
  }

  const handleResetFilters = () => {
    const ccgFilter = filters.find((filter) =>
      filter.includes('consumerGroups: ')
    )
    setFilters([ccgFilter])
    setSort(DEFAULT_SORT)
  }

  useMemo(() => {
    if (initialRender.current === true) {
      initialRender.current = false
      return
    }
    const getOffers = async () => {
      const newOffers = await getAlgoliaResults(
        0,
        filters,
        country,
        category,
        sort
      )
      setData(newOffers)
      if (hasCCGChanged) {
        setAvailableBrandFilters(newOffers?.facets?.brandName)
        setAvailableCategoryFilters(newOffers?.facets?.categories)
        setHasCCGChanged(false)
      }
    }
    getOffers()
  }, [country, sort, filters, hasCCGChanged, category])

  const handleLoadMoreClick = () => {
    const getOffers = async () => {
      const newOffers = await getAlgoliaResults(
        data.page + 1,
        filters,
        country,
        category,
        sort
      )
      setData(updateData(data, newOffers))
    }
    getOffers()
  }

  const renderFilterModal = () => {
    if (!isFilterModalOpen) return false

    return (
      <OfferListAlgoliaFiltersModal
        isOpen={isFilterModalOpen}
        handleModalClose={() => setIsFilterModalOpen(false)}
        filters={filters}
        handleFilterClick={handleFilterClick}
        handleClearFilters={handleResetFilters}
        brandFilters={availableBrandFilters}
        filteredBrandFacets={data?.facets?.brandName}
        categoryFilters={availableCategoryFilters}
        filteredCategoryFacets={data?.facets?.categories}
        handleSortChange={setSort}
        sortSelected={sort}
      />
    )
  }

  const renderHomePageCCGFilters = () => {
    if (!isHomePage) return false

    return (
      <HomePageOfferListCCGFilters
        filters={filters}
        handleCCGClick={handleCCGClick}
      />
    )
  }

  const renderCategoryPills = () => {
    if (!shouldRenderCategoryPills(isHomePage)) return false

    return <CategoryPills />
  }

  return (
    <Container
      id='discounts'
      fullWidth
    >
      {renderFilterModal()}
      {renderHomePageCCGFilters()}
      <Container>
        {renderCategoryPills()}
        <Row>
          <Column
            mother={3}
            brother={12}
            sister={12}
            baby={12}
          >
            <BeansIdButton
              variant='outlined'
              colour='huckleberry'
              css={styles.filterButtonStyles}
              onClick={() => setIsFilterModalOpen(true)}
            >
              <FilterIcon colour={Colours('huckleberry', 500)} />
              Filters
            </BeansIdButton>
            <div css={styles.filtersContainer}>
              <OfferListAlgoliaFilters
                filters={filters}
                handleOptionClick={handleFilterClick}
                handleResetFilters={handleResetFilters}
                brandFilters={availableBrandFilters}
                filteredBrandFacets={data?.facets?.brandName}
                categoryFilters={availableCategoryFilters}
                filteredCategoryFacets={data?.facets?.categories}
                handleSortChange={setSort}
                sortSelected={sort}
              />
            </div>
          </Column>
          <Column
            mother={9}
            brother={12}
            sister={12}
            baby={12}
          >
            <OfferListAlgolia
              offers={data}
              page={data.page}
              handleLoadMore={handleLoadMoreClick}
              impressionContent={IMPRESSION_CONTENT}
            />
          </Column>
        </Row>
      </Container>
    </Container>
  )
}

AlgoliaPageOfferList.propTypes = {
  offers: PropTypes.object.isRequired,
  isHomePage: PropTypes.bool
}

AlgoliaPageOfferList.defaultProps = {
  isHomePage: false
}

export default AlgoliaPageOfferList
