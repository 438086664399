import PropTypes from 'prop-types'

import UnstyledButton from '@thebeansgroup/ui_ctas/unstyled_button'
import CloseIcon from '@thebeansgroup/ui_icons/close_icon'

import BeansIdButton from '@components/beans_id_button'
import Modal from '@components/modal'
import OfferListAlgoliaFilters from '@components/offer_list_algolia_filters'
import { usePreventBodyScrolling } from '@hooks/use_prevent_body_scrolling'

import { CLEAR_ALL, CLOSE_ICON_SIZE, SHOW_OFFERS } from './constants'
import * as styles from './styles'

const OfferListAlgoliaFiltersModal = ({
  isOpen,
  handleModalClose,
  filters,
  handleFilterClick,
  handleClearFilters,
  brandFilters,
  filteredBrandFacets,
  categoryFilters,
  filteredCategoryFacets,
  handleSortChange,
  sortSelected
}) => {
  usePreventBodyScrolling()

  const renderCloseButton = () => {
    return (
      <UnstyledButton
        data-testid='close_button'
        onClick={handleModalClose}
        css={styles.closeButton}
      >
        <CloseIcon
          height={CLOSE_ICON_SIZE}
          width={CLOSE_ICON_SIZE}
        />
      </UnstyledButton>
    )
  }

  const renderClearButton = () => {
    return (
      <BeansIdButton
        onClick={handleClearFilters}
        css={styles.button}
        variant='outlined'
        colour='huckleberry'
      >
        {CLEAR_ALL}
      </BeansIdButton>
    )
  }

  const renderShowOffersButton = () => {
    return (
      <BeansIdButton
        onClick={handleModalClose}
        css={styles.button}
        colour='brightBlue'
      >
        {SHOW_OFFERS}
      </BeansIdButton>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleModalClose}
    >
      <div css={styles.filterModalContent}>
        {renderCloseButton()}
        <OfferListAlgoliaFilters
          filters={filters}
          handleOptionClick={handleFilterClick}
          brandFilters={brandFilters}
          filteredBrandFacets={filteredBrandFacets}
          categoryFilters={categoryFilters}
          filteredCategoryFacets={filteredCategoryFacets}
          handleSortChange={handleSortChange}
          sortSelected={sortSelected}
        />
        <div css={styles.buttonContainer}>
          {renderClearButton()}
          {renderShowOffersButton()}
        </div>
      </div>
    </Modal>
  )
}

OfferListAlgoliaFiltersModal.defaultProps = {
  brandFilters: {},
  filteredBrandFacets: {},
  categoryFilters: {},
  filteredCategoryFacets: {},
  sortSelected: 'most_popular'
}

OfferListAlgoliaFiltersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  handleFilterClick: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  brandFilters: PropTypes.object,
  filteredBrandFacets: PropTypes.object,
  categoryFilters: PropTypes.object,
  filteredCategoryFacets: PropTypes.object,
  handleSortChange: PropTypes.func.isRequired,
  sortSelected: PropTypes.string
}

export default OfferListAlgoliaFiltersModal
