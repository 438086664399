export const getSnapshotId = (offer) => offer?.snapshotId?.toString() || null

export const getOffersConnection = (offers) => {
  const standardisedOffersEdges = { edges: [] }
  offers?.hits?.forEach((offer) =>
    standardisedOffersEdges.edges.push({
      node: {
        title: offer?.title,
        uid: offer?.offerUid,
        impressionContent: {
          id: getSnapshotId(offer),
          type: offer?.snapshotType,
          version: offer?.snapshotVersion
        },
        brand: {
          name: offer?.brandName,
          logo: offer?.brandLogo,
          slug: offer?.brandSlug
        },
        expired: offer?.expired,
        defaultImage: offer?.defaultImage,
        defaultImageSmall: offer?.smallImage,
        activeFlags: {
          edges: offer?.activeFlags
        },
        country: {
          slug: offer?.countryCode
        },
        redemptionClass: offer?.redemptionClass,
        redemptionType: offer?.redemptionType,
        baseRedemptionType: {
          exclusive: offer?.exclusive
        },
        expiringSoon: offer?.expiringSoon,
        discountEndDate: offer?.endDate,
        subtitle: offer?.subtitle,
        slug: offer?.slug,
        closedConsumerGroup: offer?.consumerGroups
      }
    })
  )
  return standardisedOffersEdges
}

export const isLastPage = (data) => {
  return data?.page >= data?.nbPages - 1
}

export const isOffersEmpty = (offers) => {
  if (!offers) return true

  return offers.length === 0
}

export const shouldShowNoOffersBlock = (algoliaData) => {
  if (!algoliaData) return true

  return isOffersEmpty(algoliaData.hits) || !!algoliaData.algoliaError
}

export const getListPercentToNearestFive = (showing, total) => {
  const percentage = (showing / total) * 100
  return Math.ceil(percentage / 5) * 5
}
