import PropTypes from 'prop-types'

import Body from '@thebeansgroup/ui_typography/body'
import Title2 from '@thebeansgroup/ui_typography/title2'

import * as styles from './styles'

const NoOffersBlock = ({ title, subtitle }) => {
  return (
    <div css={styles.container}>
      <div css={styles.textContainer}>
        <Title2 component='h3'>{title}</Title2>
        <Body>{subtitle}</Body>
      </div>
    </div>
  )
}

NoOffersBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

NoOffersBlock.defaultProps = {
  title: "Oops! Looks like we couldn't find any discounts for you.",
  subtitle: 'Try a different filter or check back again soon.'
}

export default NoOffersBlock
