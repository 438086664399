import PropTypes from 'prop-types'
import { useState } from 'react'

import UnstyledButton from '@thebeansgroup/ui_ctas/unstyled_button'
import ChevronIconThin from '@thebeansgroup/ui_icons/chevron_icon_thin'
import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Body from '@thebeansgroup/ui_typography/body'

import { CHEVRON_HEIGHT, CHEVRON_WIDTH } from './constants'
import { getAccessibilityAttribute, getChevronDirection } from './helpers'
import * as styles from './styles'

const OfferListAlgoliaAccordionItem = ({ title, slug, open, children }) => {
  const [isOpen, setIsOpen] = useState(open)

  const renderContent = () => {
    return (
      <div
        id={getAccessibilityAttribute(slug)}
        aria-labelledby={getAccessibilityAttribute(slug)}
        css={[styles.contentContainer, !isOpen && styles.itemClosed]}
        data-testid={`${slug}-content`}
      >
        {children}
      </div>
    )
  }

  return (
    <div css={styles.accordionItem}>
      <UnstyledButton
        id={getAccessibilityAttribute(slug)}
        aria-expanded={isOpen}
        aria-controls={getAccessibilityAttribute(slug)}
        css={styles.accordionButton}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Body fontWeight='semibold'>{title}</Body>
        <ChevronIconThin
          rotateDegrees={getChevronDirection(isOpen)}
          width={CHEVRON_WIDTH}
          height={CHEVRON_HEIGHT}
          colour={Colours('liquorice', 500)}
        />
      </UnstyledButton>
      {renderContent()}
    </div>
  )
}

OfferListAlgoliaAccordionItem.defaultProps = {
  open: false
}

OfferListAlgoliaAccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  open: PropTypes.bool,
  children: PropTypes.any.isRequired
}

export default OfferListAlgoliaAccordionItem
