import { useEffect } from 'react'

import { disableBodyScolling, enableBodyScrolling } from './helpers'

export const usePreventBodyScrolling = () => {
  useEffect(() => {
    disableBodyScolling()
    return () => enableBodyScrolling()
  }, [])
}
