import { css } from '@emotion/react'

export const container = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  height: 100%;
  width: 100%;
  padding: 40px;
`

export const textContainer = css`
  text-align: center;
  position: absolute;
`
