import { css } from '@emotion/react'

export const filterModalContent = css`
  width: 100vw;
  height: calc(100vh - 50px);
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
`

export const closeButton = css`
  position: absolute;
  right: 24px;
  top: 24px;
  width: 18px;
  height: 18px;
`

export const buttonContainer = css`
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
  justify-content: space-around;
  gap: 15px;
  flex-wrap: wrap;
`

export const button = css`
  flex: 1;
  max-width: 300px;
  width: 100%;
  min-width: 150px;
`
