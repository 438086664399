import { FACE_DOWN, FACE_UP } from './constants'

export const getChevronDirection = (isOpen) => {
  if (isOpen) return FACE_DOWN

  return FACE_UP
}

export const getAccessibilityAttribute = (slug) => {
  return `accordion-item-${slug}`
}
