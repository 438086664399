import {
  ACCORDION_OPEN,
  ACCORDION_CLOSED,
  SORT_OPTIONS,
  EXTENDED_SORT_OPTIONS
} from './constants'

export const getFilters = (filters) => {
  if (!filters) return []

  return Object.entries(filters).sort((currentOption, nextOption) =>
    currentOption[0].localeCompare(nextOption[0])
  )
}

export const getBrandQuery = (brand) => {
  if (!brand) return ''

  return `brandName: "${brand}"`
}

export const getCategoryQuery = (category) => {
  if (!category) return ''

  return `categories: "${category}"`
}

export const getNumberOfOffers = (option, filteredBrandFacets) => {
  return filteredBrandFacets[option[0]] || 0
}

export const getNumberOfOffersString = (option, filteredBrandFacets) => {
  return ` (${getNumberOfOffers(option, filteredBrandFacets)})`
}

export const isSortChecked = (current, slug) => current === slug

export const getSortOptions = (hideExtendedOptions) => {
  if (hideExtendedOptions) return SORT_OPTIONS

  return EXTENDED_SORT_OPTIONS
}

export const isAccordionOpen = (hideExtendedOptions) => {
  if (hideExtendedOptions) return ACCORDION_OPEN

  return ACCORDION_CLOSED
}
