export const updateData = (previousData, newData) => {
  const previousOffers = previousData?.hits ?? []
  const newOffers = newData?.hits ?? []
  return {
    ...previousData,
    hits: [...previousOffers, ...newOffers],
    page: newData?.page
  }
}

export const isFilterChecked = (selectedFilters, value, query) => {
  return selectedFilters.includes(query || value)
}
