import { CATEGORIES } from '@components/category_pills/constants'

export const getActiveCatgorySlugs = () =>
  CATEGORIES.map((category) => category.categorySlug)

export const getCategoryNameFromSlug = (slug) => {
  const category = CATEGORIES.find((category) => category.categorySlug === slug)
  return category.categoryName
}

export const getCategoryParam = (category) => {
  if (!category) return null

  if (Array.isArray(category)) return category[0]

  return category
}

export const isValidCategory = (category) => {
  return getActiveCatgorySlugs().includes(category)
}

export const getCategoryFromParams = (params) => {
  const categoryParam = getCategoryParam(params?.cat)

  if (isValidCategory(categoryParam)) {
    const categoryName = getCategoryNameFromSlug(categoryParam)
    return categoryName
  }

  return null
}
