export const ALL_PILL_TEXT = 'All'

export const CATEGORIES = [
  {
    categoryName: 'Fashion',
    categorySlug: 'fashion'
  },
  {
    categoryName: 'Food & Drink',
    categorySlug: 'food-drink'
  },
  {
    categoryName: 'Tech & Mobile',
    categorySlug: 'tech-mobile'
  },
  {
    categoryName: 'Beauty',
    categorySlug: 'beauty'
  },
  {
    categoryName: 'Health & Fitness',
    categorySlug: 'health-fitness'
  },
  {
    categoryName: 'Travel',
    categorySlug: 'travel'
  },
  {
    categoryName: 'Entertainment',
    categorySlug: 'entertainment'
  },
  {
    categoryName: 'Gifts & Flowers',
    categorySlug: 'gifts-flowers'
  },
  {
    categoryName: 'Finance',
    categorySlug: 'finance'
  }
]
