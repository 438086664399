import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { mother } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const list = css`
  width: 100%;
  overflow-x: auto;
  list-style: none;
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 0;
  padding: 15px 10px;

  :hover::-webkit-scrollbar {
    background: ${Colours('grey', 50)};
  }

  :hover::-webkit-scrollbar-thumb {
    background-color: ${Colours('grey', 200)};
  }

  ::-webkit-scrollbar {
    height: 6px;
    color: black;
    background: transparent;
    border-radius: 10px;
    width: 50px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
  }

  ${mother} {
    border-left: none;
  }
`

export const pill = css`
  display: inline-block;
  width: max-content;
  padding: 8px 16px;
  border-radius: 50px;
  border: 1px solid ${Colours('huckleberry', 500)};
  color: ${Colours('grey', 500)};
  cursor: pointer;

  &:hover {
    color: ${Colours('grey', 500)};
    background: ${Colours('grey', 50)};
  }
`

export const active = css`
  background-color: ${Colours('huckleberry', 400)};
  color: ${Colours('white')};
  &:hover {
    color: ${Colours('white')};
    background-color: ${Colours('huckleberry', 500)};
    cursor: auto;
    user-select: none;
  }
`
