import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const scrollWrapper = css`
  overflow-x: auto;
  overflow-y: hidden;
`

export const container = css`
  padding-top: 6px;
  border-bottom: 3px solid ${Colours('grey', 100)};
`

export const iconContainer = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${desktop} {
    justify-content: center;
  }
`

export const buttonLabel = css`
  margin-top: -10px;
`

export const iconButton = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border: none;
  border-radius: 0;
  padding: 0 20px 12px 20px;
  height: 100px;
  min-width: 110px;
  margin-bottom: -3px;
  background: transparent;
  color: ${Colours('midnight', 500)};
  border-bottom: 3px solid ${Colours('grey', 100)};

  &:hover,
  &:focus {
    border-bottom: 3px solid ${Colours('huckleberry', 500)};
  }

  svg {
    fill: ${Colours('white')};
    margin-bottom: 10px;
  }
`

export const iconButtonSelected = css`
  color: ${Colours('huckleberry', 500)};
  font-weight: 600;

  border-bottom: 3px solid ${Colours('huckleberry', 500)};

  svg {
    fill: ${Colours('brightBlue')};
    stroke: ${Colours('huckleberry', 500)};
  }

  path {
    fill: ${Colours('brightBlue')};
    stroke: ${Colours('huckleberry', 500)};
  }
`
