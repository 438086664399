import { css } from '@emotion/react'

import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const filterButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;

  svg {
    margin: 0 5px 2px 0;
  }

  ${desktop} {
    display: none;
  }
`

export const filtersContainer = css`
  display: none;
  background: red;

  ${desktop} {
    display: inline;
  }
`
