import { useRouter } from 'next/router'

import { useCCG } from '@root/src/hooks/use_ccg'

import { getNextRoute } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'

import { ALL_PILL_TEXT, CATEGORIES } from './constants'
import { isActive, isAllCategoryPage } from './helpers'
import * as styles from './styles'

const CategoryPills = () => {
  const country = useCountryCode()
  const router = useRouter()
  const ccg = useCCG()

  const renderPills = () =>
    CATEGORIES.map((category, index) => {
      const { categorySlug, categoryName } = category

      return (
        <li key={index}>
          <a
            css={[styles.pill, isActive(router, categorySlug) && styles.active]}
            href={getNextRoute('discounts.ccg.category', {
              country,
              ccg,
              category: categorySlug
            })}
          >
            {categoryName}
          </a>
        </li>
      )
    })

  const renderAllPill = () => (
    <li>
      <a
        css={[styles.pill, isAllCategoryPage(router) && styles.active]}
        href={getNextRoute('discounts.ccg', {
          country,
          ccg
        })}
      >
        {ALL_PILL_TEXT}
      </a>
    </li>
  )

  return (
    <ul css={styles.list}>
      {renderAllPill()}
      {renderPills()}
    </ul>
  )
}

export default CategoryPills
