import PropTypes from 'prop-types'
import { Fragment } from 'react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Footnote from '@thebeansgroup/ui_typography/footnote'

import BeansIdButton from '@components/beans_id_button'
import NoOffersBlock from '@components/no_offers_block'
import OfferList from '@components/offer_list'

import { LOAD_MORE_DISCOUNTS_TEXT } from './constants'
import {
  getListPercentToNearestFive,
  getOffersConnection,
  isLastPage,
  shouldShowNoOffersBlock
} from './helpers'
import * as styles from './styles'

const OfferListAlgolia = ({
  offers,
  handleLoadMore,
  prioritiseFirstTile,
  category,
  impressionContent
}) => {
  const renderOffersList = () => {
    if (shouldShowNoOffersBlock(offers)) return <NoOffersBlock />

    return (
      <OfferList
        offers={getOffersConnection(offers)}
        impressionContent={impressionContent}
        prioritiseFirstTile={prioritiseFirstTile}
        mother={4}
        brother={6}
      />
    )
  }

  const renderLoadMoreButton = () => {
    if (isLastPage(offers) || shouldShowNoOffersBlock(offers)) return null

    const { nbHits, hitsPerPage, page } = offers
    const hitsShown = hitsPerPage * (page + 1)

    return (
      <div css={styles.loadMoreContainer}>
        <Footnote colour={Colours('grey', 400)}>
          Showing {hitsShown} of {nbHits}
        </Footnote>
        <div
          css={styles.progressBar(
            getListPercentToNearestFive(hitsShown, nbHits)
          )}
        />
        <BeansIdButton
          colour='brightBlue'
          variant='contained'
          onClick={handleLoadMore}
          css={styles.loadMoreButton}
        >
          {LOAD_MORE_DISCOUNTS_TEXT}
        </BeansIdButton>
      </div>
    )
  }

  return (
    <Fragment>
      {renderOffersList()}
      {renderLoadMoreButton()}
    </Fragment>
  )
}

export default OfferListAlgolia

OfferListAlgolia.propTypes = {
  offers: PropTypes.object,
  handleLoadMore: PropTypes.func.isRequired,
  prioritiseFirstTile: PropTypes.bool,
  category: PropTypes.object,
  impressionContent: PropTypes.shape({
    groupType: PropTypes.string,
    groupId: PropTypes.string,
    groupPosition: PropTypes.number
  })
}

OfferListAlgolia.defaultProps = {
  offers: {},
  prioritiseFirstTile: false,
  category: null,
  impressionContent: null
}
