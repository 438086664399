import { DEFAULT_FILTERS } from '@constants/home_page'
import FeatureToggle from '@helpers/feature_toggle'

export const getFiltersArray = (filterOption, selectedFilters) => {
  if (selectedFilters?.includes(filterOption)) {
    return selectedFilters.filter((filter) => filter !== filterOption)
  }
  return selectedFilters?.concat(filterOption)
}

export const getInitialFilters = (ccg, isHomePage) => {
  if (ccg && !isHomePage) return [`consumerGroups: "${ccg}"`]

  return DEFAULT_FILTERS
}

export const shouldRenderCategoryPills = (isHomePage) =>
  !isHomePage && FeatureToggle.isEnabled('ccg_category_page')
