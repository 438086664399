import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const loadMoreButton = css`
  margin-top: 20px;
`

export const loadMoreContainer = css`
  text-align: center;
  width: 100%;
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const progressBar = (percentage) => css`
  width: 150px;
  height: 2px;
  border-radius: 5px;
  background-color: ${Colours('grey', 100)};

  &::after {
    content: '';
    background-color: ${Colours('huckleberry', 500)};
    display: block;
    width: ${percentage}%;
    height: 100%;
    border-radius: 5px;
  }
`
