import PropTypes from 'prop-types'

import Body from '@thebeansgroup/ui_typography/body'
import Title3 from '@thebeansgroup/ui_typography/title3'

import BeansIdButton from '@components/beans_id_button'
import OfferListAlgoliaAccordionItem from '@components/offer_list_algolia_accordion_item'
import FeatureToggle from '@helpers/feature_toggle'
import { isFilterChecked } from '@helpers/offer_list_algolia'

import {
  BRANDS_SUB_TITLE,
  BRANDS_FILTER_SLUG,
  SIDE_BAR_FILTER_TITLE,
  SORT_SLUG,
  SORT_SUB_TITLE,
  SORT_OPTIONS,
  CATS_SUB_TITLE,
  CATS_FILTER_SLUG
} from './constants'
import {
  getBrandQuery,
  getCategoryQuery,
  getFilters,
  getNumberOfOffersString,
  isSortChecked
} from './helpers'
import * as styles from './styles'

const OfferListAlgoliaFilters = ({
  handleOptionClick,
  handleResetFilters,
  filters,
  brandFilters,
  filteredBrandFacets,
  categoryFilters,
  filteredCategoryFacets,
  handleSortChange,
  sortSelected
}) => {
  const renderClearFiltersButton = () => {
    if (!handleResetFilters) return false

    return (
      <BeansIdButton
        variant='text'
        colour='midnight'
        onClick={handleResetFilters}
      >
        Clear Filters
      </BeansIdButton>
    )
  }

  const renderSideBarHeader = () => {
    return (
      <div css={styles.sideBarHeader}>
        <Title3 component='h3'>{SIDE_BAR_FILTER_TITLE}</Title3>
        {renderClearFiltersButton()}
      </div>
    )
  }

  const renderFilterAccordionSection = (
    subtitle,
    slug,
    options,
    createOptionHandler,
    open
  ) => {
    return (
      <OfferListAlgoliaAccordionItem
        title={subtitle}
        slug={slug}
        open={open}
      >
        <ul css={styles.optionsList}>
          {options.map((option) => createOptionHandler(option))}
        </ul>
      </OfferListAlgoliaAccordionItem>
    )
  }

  const renderCategoryOption = (option) => {
    const optionName = option[0]
    return (
      <li key={optionName}>
        <label
          htmlFor={`${optionName}Checkbox`}
          css={styles.option}
        >
          <input
            type='checkbox'
            id={`${optionName}Checkbox`}
            name={`${optionName}_checkbox`}
            css={styles.checkbox}
            onChange={() =>
              handleOptionClick(optionName, getCategoryQuery(optionName))
            }
            checked={isFilterChecked(filters, getCategoryQuery(optionName))}
          />
          <span css={styles.label}>
            <Body fontWeight='normal'>
              {optionName}
              <span css={styles.optionNumber}>
                {getNumberOfOffersString(option, filteredCategoryFacets)}
              </span>
            </Body>
          </span>
        </label>
      </li>
    )
  }

  const renderBrandOption = (option) => {
    const optionName = option[0]
    return (
      <li key={optionName}>
        <label
          htmlFor={`${optionName}Checkbox`}
          css={styles.option}
        >
          <input
            type='checkbox'
            id={`${optionName}Checkbox`}
            name={`${optionName}_checkbox`}
            css={styles.checkbox}
            onChange={() =>
              handleOptionClick(optionName, getBrandQuery(optionName))
            }
            checked={isFilterChecked(filters, getBrandQuery(optionName))}
          />
          <span css={styles.label}>
            <Body fontWeight='normal'>
              {optionName}
              <span css={styles.optionNumber}>
                {getNumberOfOffersString(option, filteredBrandFacets)}
              </span>
            </Body>
          </span>
        </label>
      </li>
    )
  }

  const renderSortOption = (option) => {
    return (
      <li key={option.slug}>
        <label
          htmlFor={`${option.slug}Checkbox`}
          css={styles.option}
        >
          <input
            type='checkbox'
            id={`${option.slug}Checkbox`}
            name={`${option.slug}_checkbox`}
            css={styles.checkbox}
            checked={isSortChecked(sortSelected, option.slug)}
            onChange={() => handleSortChange(option.slug, option.trackingValue)}
            value={option.slug}
          />
          <span css={styles.label}>
            <Body fontWeight='normal'>{option.name}</Body>
          </span>
        </label>
      </li>
    )
  }

  const renderCategoryFilters = () => {
    return renderFilterAccordionSection(
      CATS_SUB_TITLE,
      CATS_FILTER_SLUG,
      getFilters(categoryFilters),
      renderCategoryOption,
      true
    )
  }

  const renderBrandFilters = () => {
    return renderFilterAccordionSection(
      BRANDS_SUB_TITLE,
      BRANDS_FILTER_SLUG,
      getFilters(brandFilters),
      renderBrandOption,
      true
    )
  }

  const renderSort = () => {
    if (!FeatureToggle.isEnabled('algolia_sorting')) return false

    return renderFilterAccordionSection(
      SORT_SUB_TITLE,
      SORT_SLUG,
      SORT_OPTIONS,
      renderSortOption,
      true
    )
  }

  return (
    <div css={styles.container}>
      {renderSideBarHeader()}
      {renderCategoryFilters()}
      {renderBrandFilters()}
      {renderSort()}
    </div>
  )
}

OfferListAlgoliaFilters.defaultProps = {
  filters: [],
  brandFilters: {},
  filteredBrandFacets: {},
  categoryFilters: {},
  filteredCategoryFacets: {},
  sortSelected: 'most_popular',
  handleResetFilters: null
}

OfferListAlgoliaFilters.propTypes = {
  handleOptionClick: PropTypes.func.isRequired,
  handleResetFilters: PropTypes.func,
  filters: PropTypes.array,
  brandFilters: PropTypes.object,
  filteredBrandFacets: PropTypes.object,
  categoryFilters: PropTypes.object,
  filteredCategoryFacets: PropTypes.object,
  handleSortChange: PropTypes.func.isRequired,
  sortSelected: PropTypes.string
}

export default OfferListAlgoliaFilters
