import { captureException } from '@sentry/nextjs'
import algoliasearch from 'algoliasearch/lite'

import FeatureToggle from '@helpers/feature_toggle'
import { getTimeNowSeconds } from '@helpers/time'

export const getFilters = (filters) => {
  if (filters.length === 0) return ''

  return filters.join(' OR ') + ' AND'
}

export const getCategoryFilters = (filters, category) => {
  const algoliaFilters = getFilters(filters)

  if (!category || !FeatureToggle.isEnabled('ccg_category_page'))
    return algoliaFilters

  if (!algoliaFilters) return `categories: "${category}" AND`

  return `categories: "${category}" OR ${algoliaFilters}`
}

export const getAlgoliaFilters = (filters, countrySlug, category) => {
  const brandFilters = filters.filter((filter) => filter.includes('brandName:'))
  const categoryFilters = filters.filter((filter) =>
    filter.includes('categories:')
  )
  const ccgFilter = filters.filter((filter) =>
    filter.includes('consumerGroups:')
  )

  return `countryCode: ${countrySlug} AND \
    enabled: true AND \
    contentType: native_student_discount AND \
    ${getFilters(brandFilters)} \
    ${getCategoryFilters(categoryFilters, category)} \
    startDate <= ${getTimeNowSeconds()} AND \
    endDate >= ${getTimeNowSeconds()} AND \
    ${getFilters(ccgFilter)} NOT \
    denyListedConsumerUids: ${
      process.env.NEXT_PUBLIC_DENY_LISTED_CONSUMER_UIDS
    }`
}

export const getAlgoliaIndex = () => {
  return algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
  ).initIndex(process.env.NEXT_PUBLIC_ALGOLIA_DISCOUNT_INDEX)
}

export const reportAlgoliaError = () => {
  return { algoliaError: true }
}

export const getAlgoliaResults = async (
  page,
  filters,
  countrySlug,
  category
) => {
  try {
    const algoliaIndex = getAlgoliaIndex()
    const results = await algoliaIndex.search('', {
      filters: getAlgoliaFilters(filters, countrySlug, category),
      hitsPerPage: 9,
      page,
      facets: ['brandName', 'categories']
    })

    return results
  } catch (error) {
    captureException(error.message)
    return reportAlgoliaError()
  }
}
