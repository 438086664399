export const blurActiveElement = () => document.activeElement?.blur()

export const disableBodyScolling = () => {
  document.body.addEventListener('touchmove', blurActiveElement)
  document.body.style.overflow = 'hidden'
  document.body.style.position = 'fixed'
  document.body.style.height = '100%'
  document.body.style.width = '100%'
}

export const enableBodyScrolling = () => {
  document.body.removeEventListener('touchmove', blurActiveElement)
  document.body.style.overflow = ''
  document.body.style.position = ''
  document.body.style.height = ''
  document.body.style.width = ''
}
