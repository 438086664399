import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { mother } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const accordionItem = css`
  width: 100%;
  border-bottom: 1px solid ${Colours('grey', 100)};
  justify-self: flex-start;
  flex: 0 1;
  height: 100%;
`

export const accordionButton = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  ${mother} {
    padding: 20px 0 10px;
  }
`

export const itemClosed = css`
  display: none;
`

export const contentContainer = css`
  height: 100%;
`
