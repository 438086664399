import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const container = css`
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
`

export const sideBarHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const optionsList = css`
  list-style: none;
  padding: 0 0 10px 0;
  margin-top: 15px;
  margin: 0;
  overflow-y: auto;
  height: 100%;
  max-height: 200px;
`

export const option = css`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
export const checkbox = css`
  width: 20px;
  height: 20px;
  margin: 0 12px 0 2px;
  border: 2px solid ${Colours('grey', 300)};
  cursor: pointer;
  position: relative;

  appearance: none;
  background-color: transparent;

  &:checked {
    background-color: ${Colours('brightBlue')};
    border: 2px solid ${Colours('brightBlue')};
    color: ${Colours('huckleberry', 500)};
    font-size: 25px;

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 0.5px;

      height: 6px;
      width: 13px;
      border-left: 3px solid;
      border-bottom: 3px solid;

      transform: rotate(-45deg);
    }
  }

  &:focus,
  &:hover {
    border: 2px solid ${Colours('huckleberry', 500)};
  }
`

export const label = css`
  user-select: none;
  font-weight: 500;
  font-size: 16px;
`
export const sectionTitle = css`
  padding-bottom: 10px;
`

export const section = css`
  width: 100%;
  border-bottom: 1px solid ${Colours('grey', 100)};
  padding-top: 15px;
`

export const optionNumber = css`
  color: ${Colours('grey', 400)};
`
