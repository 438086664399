import PropTypes from 'prop-types'

import Container from '@components/container'
import { ACTIVE_CONSUMER_GROUPS } from '@constants/consumer_groups'

import { isCCGActive } from './helpers'
import * as styles from './styles'

const HomePageOfferListCCGFilters = ({ filters, handleCCGClick }) => {
  const renderIcons = () =>
    ACTIVE_CONSUMER_GROUPS.map(({ id, label, icon: Icon }) => (
      <button
        key={id}
        css={[
          styles.iconButton,
          isCCGActive(id, filters) && styles.iconButtonSelected
        ]}
        onClick={() => handleCCGClick(`consumerGroups: "${id}"`)}
      >
        <Icon
          width='100'
          height='100'
        />
        <span css={styles.buttonLabel}>{label}</span>
      </button>
    ))

  return (
    <div css={styles.scrollWrapper}>
      <div css={styles.container}>
        <Container>
          <div css={styles.iconContainer}>{renderIcons()}</div>
        </Container>
      </div>
    </div>
  )
}

HomePageOfferListCCGFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  handleCCGClick: PropTypes.func.isRequired
}

export default HomePageOfferListCCGFilters
