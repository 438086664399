export const SORT_OPTIONS = [
  {
    name: 'Most popular',
    slug: 'most_popular',
    trackingValue: 'sort: Most Popular'
  },
  {
    name: 'New in',
    slug: 'new_in',
    trackingValue: 'sort: New In'
  },
  {
    name: 'Ending soon',
    slug: 'ending_soon',
    trackingValue: 'sort: Ending Soon'
  }
]

export const SIDE_BAR_FILTER_TITLE = 'Filters'

export const OFFER_TYPE_FILTER_TITLE = 'Offer type'

export const OFFER_TYPE_FILTER_SLUG = 'offer_type'

export const CATS_FILTER_SLUG = 'categories'

export const CATS_SUB_TITLE = 'Categories'

export const BRANDS_FILTER_SLUG = 'brands'

export const BRANDS_SUB_TITLE = 'Brands'

export const SORT_SUB_TITLE = 'Sort by'

export const SORT_SLUG = 'sort'

export const ACCORDION_OPEN = true

export const ACCORDION_CLOSED = false

export const DEFAULT_BRANDS = []
