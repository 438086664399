import { ACTIVE_CONSUMER_GROUPS } from './consumer_groups'

export const DEFAULT_SORT = 'most_popular'
export const DEFAULT_FILTERS = [
  `consumerGroups: "${ACTIVE_CONSUMER_GROUPS[0].id}"`
]

export const FAQ_TITLE = 'FAQs'
export const FAQS = [
  {
    question: 'Am I eligible for BeansiD?',
    answer:
      'BeansiD has a variety of different consumer groups on offer. The eligibility criteria is as follows: \n * Healthcare - employee Id, recent payslip and for the UK only NHS email verification \n * Military - Military ID badge, recent payslip \n * Teacher - employee ID badge, recent payslip \n * First responder - employee iD, recent payslip'
  },
  {
    question: 'How do I manually verify?',
    answer:
      'In the verification journey, you will be prompted to upload proof if alternative verification methods are not applicable. You are able to upload in the following formats: jpg, png or pdf.'
  },
  {
    question: 'Is there a BeansiD app?',
    answer: 'Right now there is not a BeansiD app.'
  },
  {
    question: 'What is BeansiD?',
    answer:
      'BeansiD offers collection of discounts at your favourite brands for a variety of different consumer groups. If you are able to verify for one of the consumer groups then this will unlock the discounts for you under that consumer group.'
  }
]
