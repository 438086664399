import PropTypes from 'prop-types'

import Column from '@thebeansgroup/ui_grids/column'
import Row from '@thebeansgroup/ui_grids/row'
import Title3 from '@thebeansgroup/ui_typography/title3'

import OfferTile from '@components/offer_tile'

import { shouldPrioritiseFirstTile } from './helpers'

const OfferList = ({
  title,
  offers,
  suffix,
  impressionContent,
  mother,
  brother,
  prioritiseFirstTile,
  shouldRenderTitle
}) => {
  const renderTitle = () => {
    if (!shouldRenderTitle) return null

    return <Title3 component='h2'>{title}</Title3>
  }

  const renderOffers = () => {
    return offers?.edges?.map(({ node }, index) => {
      const priority = shouldPrioritiseFirstTile(prioritiseFirstTile, index)
      return (
        <Column
          key={node.uid}
          mother={mother}
          brother={brother}
          sister={12}
          baby={12}
        >
          <OfferTile
            priority={priority}
            offer={node}
            impressionContent={{
              ...impressionContent,
              contentPosition: index
            }}
          />
        </Column>
      )
    })
  }

  return (
    <section>
      {renderTitle()}
      <Row>{renderOffers()}</Row>
    </section>
  )
}

OfferList.propTypes = {
  title: PropTypes.string.isRequired,
  offers: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.object.isRequired
      }).isRequired
    ).isRequired
  }),
  suffix: PropTypes.string.isRequired,
  impressionContent: PropTypes.shape({
    groupType: PropTypes.string,
    groupId: PropTypes.string,
    groupPosition: PropTypes.number
  }),
  mother: PropTypes.number,
  brother: PropTypes.number,
  prioritiseFirstTile: PropTypes.bool,
  shouldRenderTitle: PropTypes.bool
}

OfferList.defaultProps = {
  title: '',
  offers: {
    edges: []
  },
  suffix: '',
  mother: 4,
  brother: 4,
  prioritiseFirstTile: false,
  impressionContent: null,
  shouldRenderTitle: true
}

export default OfferList
